import "@vendor-app/app/AdminRoute/AdminSmartrrAccount/components/AccountPlans.css";
import { Icon, LegacyStack, MediaCard, Page, Text } from "@shopify/polaris";
import { LockMajor } from "@shopify/polaris-icons";
import { adminRoutePrefix } from "@smartrr/shared/constants";
import { FeatureEnum } from "@smartrr/shared/entities/AccountPlan";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { navigateWithShopInQuery } from "@vendor-app/utils/navigateWithShopInQuery";
import { isFeatureAvailable } from "./featureAccess";
import { useHasTrialExpired } from "@vendor-app/utils/useHasTrialExpired";
import { FreeTrialExpiredPage } from "../elements/FreeTrialExpiredPage";
import BundlesGatedImage from "./../styles/BundlesGatedImage.png";
import IntegrationsGatedImage from "./../styles/IntegrationsGatedImage.png";
import LoyaltyGatedImage from "./../styles/LoyaltyGatedImage.png";
import ReportsGatedImage from "./../styles/ReportsGatedImage.png";
import RetentionGatedImage from "./../styles/RetentionGatedImage.png";
import TranslationsGatedImage from "./../styles/TranslationsGatedImage.png";

const CardWrapper = styled.div`
  .Polaris-Button {
    background: #008060;
    color: #ffffff;
    &:hover {
      background: #006e52;
    }
  }
`;

interface IGatedCard {
  pageTitle?: string;
  buttonText: string;
  cardTitle: string;
  cardDescription: string;
  cardImage: string;
}

enum Path {
  RETENTION = "/admin/configure/churn-prevention",
  REPORTS = "/admin/analytics",
  BUNDLES = "/admin/configure/bundles",
  LOYALTY = "/admin/loyalty",
  INTEGRATIONS = "/admin/configure/integrations",
  TRANSLATIONS = "/admin/configure/translations",
  ANALYTICS = "/admin/advanced-analytics",
  CUSTOMERS = "/admin/customers",
  SUBSCRIPTIONS = "/admin/subscriptions",
  NOTIFICATIONS = "/admin/configure/subscriber-engagement",
  THEMING = "/admin/configure/theming",
  PLANS = "/admin/configure/plans",
  ORDERS = "/admin/orders",
  TRANSACTIONS = "/admin/bills",
  SHIPPING = "/admin/configure/delivery-profiles",
  TRANSACTION_SETTINGS = "/admin/configure/billing-settings",
}

const pageTitleMap: Record<Path, string> = {
  [Path.RETENTION]: "Retention",
  [Path.BUNDLES]: "Bundles",
  [Path.INTEGRATIONS]: "Integrations",
  [Path.LOYALTY]: "Loyalty",
  [Path.TRANSLATIONS]: "Translations",
  [Path.REPORTS]: "Reports",
  [Path.ANALYTICS]: "Analytics",
  [Path.CUSTOMERS]: "Customers",
  [Path.SUBSCRIPTIONS]: "Subscriptions",
  [Path.NOTIFICATIONS]: "Notifications",
  [Path.THEMING]: "Theming",
  [Path.PLANS]: "Subscription Programs",
  [Path.ORDERS]: "Orders",
  [Path.TRANSACTIONS]: "Transactions",
  [Path.SHIPPING]: "Shipping",
  [Path.TRANSACTION_SETTINGS]: "Transaction Settings",
};

const gatedCardContentMap: Partial<Record<Path, IGatedCard>> = {
  [Path.RETENTION]: {
    pageTitle: "Retention",
    buttonText: "📈 Upgrade to Grow",
    cardTitle: "Retention unavailable on current plan",
    cardDescription: "Upgrade to Grow to gain insight on customer cancellations and improve retention.",
    cardImage: RetentionGatedImage,
  },
  [Path.BUNDLES]: {
    pageTitle: "Bundles",
    buttonText: "💎 Upgrade to Excel",
    cardTitle: "Bundles unavailable on current plan",
    cardDescription: "Upgrade to Excel to offer custom 'build-a-box' type subscriptions.",
    cardImage: BundlesGatedImage,
  },
  [Path.INTEGRATIONS]: {
    pageTitle: "Integrations",
    buttonText: "📈 Upgrade to Grow",
    cardTitle: "Integrations unavailable on current plan",
    cardDescription: "Upgrade to Grow to access integrations with Klaviyo, LoyaltyLion, and more.",
    cardImage: IntegrationsGatedImage,
  },
  [Path.LOYALTY]: {
    pageTitle: "Loyalty",
    buttonText: "💎 Upgrade to Excel",
    cardTitle: "Loyalty unavailable on current plan",
    cardDescription: "Upgrade to Excel to incentivize customers with rewards through referrals.",
    cardImage: LoyaltyGatedImage,
  },
  [Path.TRANSLATIONS]: {
    pageTitle: "Translations",
    buttonText: "📈 Upgrade to Grow",
    cardTitle: "Translations unavailable on current plan",
    cardDescription: "Upgrade to Grow to translate your Customer Account Portal into French or Spanish.",
    cardImage: TranslationsGatedImage,
  },
  [Path.REPORTS]: {
    pageTitle: "Reports",
    buttonText: "📈 Upgrade to Grow",
    cardTitle: "Reports unavailable on current plan",
    cardDescription: "Upgrade to Grow for basic analytics, or Excel for Advanced Analytics powered by Looker.",
    cardImage: ReportsGatedImage,
  },
};

const GatedFeatureCard = ({ content }: { content: IGatedCard }) => (
  <Page narrowWidth title={content.pageTitle}>
    <CardWrapper>
      <MediaCard
        title={
          <LegacyStack spacing="tight" wrap={false}>
            <Icon source={LockMajor} color="base" />
            <Text variant="headingMd" as="h2">
              {content.cardTitle}
            </Text>
          </LegacyStack>
        }
        primaryAction={{
          content: content.buttonText,
          onAction: () => navigateWithShopInQuery(`${adminRoutePrefix}/smartrr-account`),
        }}
        description={content.cardDescription}
      >
        <img
          alt=""
          width="100%"
          height="100%"
          style={{ objectFit: "cover", objectPosition: "center" }}
          src={content.cardImage}
        />
      </MediaCard>
    </CardWrapper>
  </Page>
);

export function AuthorizedRoute(feature: FeatureEnum, component: React.ReactNode): React.ReactNode {
  const currentPath = window.location.pathname as Path;
  const [pageTitle, setPageTitle] = useState("");

  const activePlan = useSmartrrVendorSelector(state => state.accountPlans.activePlan);
  const user = useSmartrrVendorSelector(state => state.auth.user);

  useEffect(() => {
    setPageTitle(pageTitleMap[currentPath]);
  }, [currentPath]);

  const hasTrialExpired = useHasTrialExpired();

  if (hasTrialExpired) {
    return <FreeTrialExpiredPage pageTitle={pageTitle} />;
  }

  if (isFeatureAvailable(feature, user, activePlan)) {
    return component;
  }

  const cardContent = gatedCardContentMap[currentPath];

  return cardContent ? <GatedFeatureCard content={cardContent} /> : <Page title={pageTitle} />;
}
